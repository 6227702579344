const theme = {
  "space": [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512,
  ],
  "fonts": {
    "body": "Inter, sans-serif",
    "heading": "Inter, sans-serif",
    "monospace": "Menlo, monospace",
  },
  "fontSizes": [
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    64,
    96,
  ],
  "fontWeights": {
    "body": 400,
    "heading": 900,
    "bold": 700,
  },
  "lineHeights": {
    "body": 1.625,
    "heading": 1.25,
  },
  "colors": {
    "text": "#000",
    "background": "#fff",
    "primary": "#609",
    "secondary": "#306",
    "muted": "#f6f6f6",
  },
  "styles": {
    "root": {
      "fontFamily": "body",
      "lineHeight": "body",
      "fontWeight": "body",
    },
    "h1": {
      "color": "text",
      "fontFamily": "heading",
      "lineHeight": "heading",
      "fontWeight": "heading",
      "fontSize": 8,
    },
    "h2": {
      "color": "text",
      "fontFamily": "heading",
      "lineHeight": "heading",
      "fontWeight": "heading",
      "fontSize": 4,
    },
    "h3": {
      "color": "text",
      "fontFamily": "heading",
      "lineHeight": "heading",
      "fontWeight": "heading",
      "fontSize": [4, 5],
    },
    "h4": {
      "color": "text",
      "fontFamily": "heading",
      "lineHeight": "heading",
      "fontWeight": "heading",
      "fontSize": 2,
    },
    "h5": {
      "color": "text",
      "fontFamily": "heading",
      "lineHeight": "heading",
      "fontWeight": "heading",
      "fontSize": 1,
    },
    "h6": {
      "color": "text",
      "fontFamily": "heading",
      "lineHeight": "heading",
      "fontWeight": "heading",
      "fontSize": 0,
    },
    "p": {
      "color": "text",
      "fontFamily": "body",
      "fontWeight": "body",
      "lineHeight": "body",
    },
    "a": {
      "color": "primary",
    },
    "pre": {
      "fontFamily": "monospace",
      "overflowX": "auto",
      "code": {
        "color": "inherit",
      },
    },
    "code": {
      "fontFamily": "monospace",
      "fontSize": "inherit",
    },
    "table": {
      "width": "100%",
      "borderCollapse": "separate",
      "borderSpacing": 0,
    },
    "th": {
      "textAlign": "left",
      "borderBottomStyle": "solid",
    },
    "td": {
      "textAlign": "left",
      "borderBottomStyle": "solid",
    },
    "img": {
      "maxWidth": "100%",
    },
  },
}

export default theme
